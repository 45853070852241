export type AbstractMetricData = object;

export interface AccessManagementRuleDto {
  id?: string;
  email?: string;
  isAdmin?: boolean;
  permissionData?: PermissionData[];
  permissions?: string[];
}

export interface AnonymizationRuleDto {
  id?: string;
  projectId?: string;
  key?: string;
  value?: string;
}

export enum AuditFrequency {
  Daily = "Daily",
  Weekly = "Weekly",
}

export interface AuditInstanceDto {
  endpointId?: string;
  projectId?: string;
  environmentId?: string;
  id?: string;
  recommendationId?: string | null;
  auditTemplateId?: string;
  auditType?: AuditType;
  auditTypeString?: string;
  endpointHostName?: string;
  isDisabled?: boolean;
}

export interface AuditTemplateDto {
  id?: string;
  auditType?: AuditType;
  auditTypeString?: string;
  recommendationTemplateId?: string;
  requiredStage?: EndpointStage;
  endpointStageString?: string;
  runFrequency?: AuditFrequency;
  allowedEndpointClasses?: EndpointClass[];
}

export enum AuditType {
  SSLTerminatedAtEdge = "SSLTerminatedAtEdge",
  StaticItemsCached = "StaticItemsCached",
}

export interface AzureDevOpsBuildInfo {
  _links?: Links;
  properties?: Properties;
  tags?: any[];
  validationResults?: Validationresult[];
  plans?: Plan[];
  triggerInfo?: Triggerinfo;
  /** @format int32 */
  id?: number;
  buildNumber?: string;
  status?: string;
  result?: string;
  /** @format date-time */
  queueTime?: Date;
  /** @format date-time */
  startTime?: Date;
  /** @format date-time */
  finishTime?: Date;
  url?: string;
  definition?: Definition;
  /** @format int32 */
  buildNumberRevision?: number;
  project?: Project;
  uri?: string;
  sourceBranch?: string;
  sourceVersion?: string;
  queue?: Queue;
  priority?: string;
  reason?: string;
  requestedFor?: Requestedfor;
  requestedBy?: Requestedby;
  /** @format date-time */
  lastChangedDate?: Date;
  lastChangedBy?: Lastchangedby;
  orchestrationPlan?: Orchestrationplan;
  logs?: Logs;
  repository?: Repository;
  retainedByRelease?: boolean;
  triggeredByBuild?: Triggeredbybuild;
  appendCommitMessageToRunName?: boolean;
}

export interface Badge {
  href?: string;
}

export interface BuildDefinitionDto {
  /** @format int32 */
  id?: number;
  name?: string;
  system?: DeploymentSystem;
}

export enum BuildPhaseStatus {
  Undefined = "Undefined",
  OK = "OK",
  InProgress = "InProgress",
  Failed = "Failed",
  NotStarted = "NotStarted",
  Cancelled = "Cancelled",
  CompletedWithErrors = "CompletedWithErrors",
}

export interface BusinessGoalData {
  /** @format date-time */
  date?: Date;
  businessGoalId?: string;
  metricData?: DoubleValueMetricData;
  metric?: Metric;
  coreWebVitalsMetric?: CoreWebVitalsMetric | null;
}

export interface BusinessGoalDataDto {
  /** @format date-time */
  date?: Date;
  businessGoalId?: string;
  metricData?: AbstractMetricData;
  metric?: Metric;
}

export interface BusinessGoalDto {
  id?: string;
  metric?: Metric;
  coreWebVitalsMetric?: CoreWebVitalsMetric | null;
  metricUnit?: MetricUnit;
  metricString?: string;
  urlScope?: string | null;
  urlScopeOperator?: UrlScopeOperator | null;
  urlScopeOperatorName?: string;
  geoScope?: GeoScope | null;
  geoScopeName?: string;
  metricOperator?: MetricOperator;
  targets?: BusinessGoalTargetDto[];
  latestTarget?: BusinessGoalTargetDto;
  /** @format double */
  value?: number;
  hostNameString?: string;
  hostNames?: string[];
  hostNameValues?: string[];
  scope?: string;
  path?: string;
  categoryIcon?: string;
  device?: Device | null;
  projectId?: string;
  categoryLabel?: string;
  query?: string;
}

export interface BusinessGoalResultDto {
  businessGoalId?: string;
  exceptions?: string[];
  /** @format date-time */
  lastRun?: Date;
  lastFetchedData?: BusinessGoalData[];
  status?: string;
  resolutionString?: string;
}

export interface BusinessGoalTargetDto {
  businessGoalId?: string;
  value?: string;
  /** @format date-time */
  effectiveFrom?: Date;
}

export interface ChangeDashboardLogoRequest {
  logoId?: LogoId | null;
}

export interface ChangeDashboardViewOrderRequest {
  dashboardId?: string;
  dashboardViewId?: string;
  /** @format int32 */
  order?: number;
}

export enum CoreWebVitalsMetric {
  PageSpeed = "PageSpeed",
  CumulativeLayoutShift = "CumulativeLayoutShift",
  FirstContentfulPaint = "FirstContentfulPaint",
  FirstMeaningfulPaint = "FirstMeaningfulPaint",
  LargestContentfulPaint = "LargestContentfulPaint",
  PageWeight = "PageWeight",
  SpeedIndex = "SpeedIndex",
  TimeToFirstByte = "TimeToFirstByte",
  TimeToInteractive = "TimeToInteractive",
  TotalBlockingTime = "TotalBlockingTime",
}

export interface CreateAccessManagementRuleRequest {
  id?: string;
  email?: string;
  customerIds?: string[];
  projectIds?: string[];
  isAdmin?: boolean;
}

export interface CreateAnonymizationRuleRequest {
  projectId?: string;
  id?: string;
  key?: string;
  value?: string;
}

export interface CreateAuditTemplateRequest {
  id?: string;
  auditType?: AuditType;
  recommendationTemplateId?: string;
  runFrequency?: AuditFrequency;
  requiredStage?: EndpointStage;
  allowedEndpointClasses?: EndpointClass[];
}

export interface CreateBusinessGoalRequest {
  id?: string;
  projectId?: string;
  environmentId?: string;
  metric?: Metric;
  coreWebVitalsMetric?: CoreWebVitalsMetric | null;
  urlScope?: string | null;
  urlScopeOperator?: UrlScopeOperator | null;
  geoScope?: GeoScope | null;
  metricOperator?: MetricOperator;
  value?: string;
  hostNames?: string[];
  path?: string;
  device?: Device | null;
}

export interface CreateCustomerRequest {
  id?: string;
  name?: string;
  displayName?: string;
}

export interface CreateDashboardRequest {
  id?: string;
  projectId?: string;
  title?: string;
  logoId?: LogoId | null;
  zoomBox?: ZoomBoxConfigurationDto;
  style?: DashboardStyle;
}

export interface CreateDashboardViewRequest {
  id?: string;
  dashboardId?: string;
  type?: DashboardViewType;
  title?: string | null;
  /** @format int32 */
  duration?: number;
  zoomBox?: ZoomBoxConfigurationDto | null;
  timeRange?: TimeRange | null;
  hostInclude?: string[] | null;
  /** @format int32 */
  sloMs?: number | null;
  /** @format int32 */
  order?: number;
  customJson?: string;
  dashboardViewFilters?: DashboardViewFilter[];
}

export interface CreateGobelinDeviceRequest {
  id?: string;
  deviceIdentifier?: string;
  projectId?: string | null;
}

export interface CreateProjectRequest {
  id?: string;
  customerId?: string;
  name?: string;
  shortName?: string;
  displayName?: string;
}

export interface CreateRecommendationRequest {
  id?: string;
  projectId?: string;
  environmentId?: string;
  category?: string;
  title?: string;
  mitigationImpact?: string;
  mitigationActors?: string[];
  mitigationEffort?: MitigationEffort;
  recommendationBody?: string;
  recommendedAction?: RecommendedAction;
  status?: RecommendationStatus;
  createdBy?: string;
  links?: RecommendationBusinessGoalLinkDto[];
}

export interface CreateRecommendationTemplateRequest {
  id?: string;
  category?: string;
  title?: string;
  mitigationImpact?: string;
  mitigationActors?: string[];
  mitigationEffort?: MitigationEffort;
  recommendationBody?: string;
  recommendedAction?: RecommendedAction;
  status?: RecommendationStatus;
}

export interface CreateWhitelistFirewallRuleRequest {
  ipRule?: string;
  whitelistId?: string;
  whitelistFirewallRuleId?: string;
  description?: string;
}

export interface CreateWhitelistRequest {
  projectId?: string;
  name?: string;
  whitelistId?: string;
}

export interface CustomerDto {
  id?: string;
  name?: string;
  displayName?: string;
  defaultProjectId?: string;
}

export interface DashboardDto {
  id?: string;
  projectId?: string;
  customId?: string;
  title?: string;
  logoId?: LogoId | null;
  logoUrl?: string;
  logoBase64?: string;
  optimizedLogoBase64?: string;
  dashboardUrl?: string;
  whitelistId?: string | null;
  zoomBox?: ZoomBoxConfigurationDto;
  style?: DashboardStyle;
  styleString?: string;
}

export enum DashboardStyle {
  RoadShadedRelief = "road_shaded_relief",
  Night = "night",
  Satellite = "satellite",
}

export interface DashboardViewDto {
  id?: string;
  dashboardId?: string;
  type?: DashboardViewType;
  typeString?: string;
  title?: string | null;
  /** @format int32 */
  duration?: number;
  zoomBox?: ZoomBoxConfigurationDto | null;
  timeRange?: TimeRange | null;
  timeRangeString?: string;
  hostInclude?: string[] | null;
  /** @format int32 */
  sloMs?: number | null;
  isEnabled?: boolean;
  isTemplate?: boolean;
  /** @format int32 */
  order?: number;
  description?: string;
  customJson?: string | null;
  dashboardViewFilters?: DashboardViewFilter[];
}

export interface DashboardViewFilter {
  method?: string;
  criteria?: string;
}

export enum DashboardViewType {
  LivePageRequests = "LivePageRequests",
  Errors = "Errors",
  Orders = "Orders",
  WAF = "WAF",
  SLO = "SLO",
  POP = "POP",
  Custom = "Custom",
}

export interface DdosCountryIpCount {
  country?: string;
  /** @format int64 */
  ipHits?: number;
  coordinates?: LatLong[];
}

export interface DdosOverviewDto {
  id?: string;
  /** @format date-time */
  startTime?: Date;
  /** @format int64 */
  seconds?: number;
  /** @format double */
  totalPackets?: number;
  /** @format double */
  totalPacketsDropped?: number;
  /** @format double */
  mitigationPercentage?: number | null;
  countries?: DdosCountryIpCount[];
  vectors?: string[];
  history?: Int32Int32DatedValue[];
  status?: string;
  endTime?: any;
  startTimeRaw?: string | null;
  endTimeRaw?: string | null;
}

export interface Definition {
  drafts?: any[];
  /** @format int32 */
  id?: number;
  name?: string;
  url?: string;
  uri?: string;
  path?: string;
  type?: string;
  queueStatus?: string;
  /** @format int32 */
  revision?: number;
  project?: Project;
}

export interface DeploymentInfo {
  buildNumber?: string;
  /** @format int32 */
  buildId?: number;
  logs?: PhasedAzureDevOpsBuildLogs[];
  locations?: DeploymentLocation[];
  status?: DeploymentStatus;
  isCancelled?: boolean;
  /** @format date-time */
  queueTime?: Date;
  currentPhase?: PhaseDetails;
  /** @format date-time */
  finishTime?: Date;
  /** @format int32 */
  longestLocationDeploySeconds?: number | null;
  /** @format int32 */
  totalTimeSeconds?: number | null;
  requestedBy?: string;
  /** @format int32 */
  index?: number;
  /** @format int32 */
  totalCount?: number;
  /** @format date-time */
  buildFinishTime?: Date;
  isLoadingPlaceholder?: boolean;
}

export interface DeploymentLocation {
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  name?: string;
  /** @format date-time */
  startTime?: Date;
  /** @format date-time */
  endTime?: Date;
  status?: BuildPhaseStatus;
}

export enum DeploymentStatus {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Completed = "Completed",
  Failed = "Failed",
  Undefined = "Undefined",
  Cancelled = "Cancelled",
  CompletedWithErrors = "CompletedWithErrors",
}

export interface DeploymentSummaryDto {
  repositoryInfoId?: string;
  repositoryName?: string;
  repositoryUrl?: string;
  repositoryBranch?: string;
  deploymentStatus?: DeploymentStatus;
  inProgressDeployment?: DeploymentInfo | null;
  activeDeployments?: DeploymentInfo[] | null;
  activeDeployment?: DeploymentInfo;
  domains?: string[];
  locations?: DeploymentLocation[];
  /** @format int32 */
  longestLocationDeploySeconds?: number | null;
  /** @format int32 */
  totalTimeSeconds?: number | null;
  /** @format date-time */
  startTime?: Date;
  isFullyLoaded?: boolean;
  versions?: AzureDevOpsBuildInfo[];
}

export enum DeploymentSystem {
  AzureDevOps = "AzureDevOps",
}

export enum Device {
  Mobile = "Mobile",
  Desktop = "Desktop",
}

export interface DoubleValueMetricData {
  /** @format double */
  value?: number;
}

export interface DropdownOption {
  value?: string;
  label?: string;
}

export enum EndpointClass {
  Frontend = "frontend",
  Frontendprivate = "frontendprivate",
  Admin = "admin",
  Service = "service",
  Serviceprivate = "serviceprivate",
  Redirect = "redirect",
  Public = "Public",
}

export interface EndpointDto {
  id?: string;
  endpointType?: EndpointType | null;
  endpointTypeName?: string;
  targetType?: string;
  hostName?: string;
  probePath?: string;
  /** @format int32 */
  pool?: number;
  origins?: string;
  platformType?: string;
  isEnabled?: boolean;
  isRetired?: boolean;
  stage?: string | null;
  /** @format int32 */
  stageValue?: number;
  status?: string;
  projectId?: string;
  endpointClass?: EndpointClass | null;
  endpointClassName?: string;
  environment?: string | null;
  /** @format int64 */
  requestCount?: number | null;
  /** @format int64 */
  egressMb?: number | null;
  isCachingEnabled?: boolean | null;
  isImageOptimizationEnabled?: boolean | null;
  /** @format int64 */
  errorCount?: number | null;
  /** @format double */
  errorPercentage?: number | null;
  hasWafBotProtection?: boolean | null;
  hasWafCoreRuleset?: boolean | null;
  hasWafCustomRules?: boolean | null;
  /** @format double */
  uptimePercent30d?: number | null;
  notCachedEgress30d?: Int64DatedValue[] | null;
  cachedEgress30d?: Int64DatedValue[] | null;
  blocks30d?: Int64DatedValue[] | null;
  errors30d?: Int64DatedValue[] | null;
}

export enum EndpointStage {
  Stage0 = "stage0",
  Stage1Inactive = "stage1inactive",
  Stage1Active = "stage1active",
  Stage2 = "stage2",
  Stage3 = "stage3",
  Shadow = "shadow",
}

export enum EndpointType {
  GlobalEndpoint = "GlobalEndpoint",
  GlobalEndpoints = "GlobalEndpoints",
  GlobalEndpointAlias = "GlobalEndpointAlias",
  ServiceEndpoint = "ServiceEndpoint",
  Redirect = "Redirect",
  GlobalEndpointRedirect = "GlobalEndpointRedirect",
  System = "System",
}

export interface ExpandedDeployments {
  projectExpandedDeployments?: Record<string, string[]>;
}

export interface FeatureFlagsConfiguration {
  isDdosMitigationEnabled?: boolean;
}

export interface FirewallActionCountDto {
  /** @format int64 */
  logs?: number;
  /** @format int64 */
  blocks?: number;
  /** @format int64 */
  anomalyScores?: number;
}

export interface FirewallBotRuleDetailsDto {
  grouping?: string;
  countries?: string[];
  /** @format int32 */
  urls?: number;
  /** @format int64 */
  kBytes?: number;
  /** @format int64 */
  requests?: number;
  /** @format int64 */
  requests200?: number;
  /** @format int64 */
  requests300?: number;
  /** @format int64 */
  requests403?: number;
  /** @format int64 */
  requests4xx?: number;
  /** @format int64 */
  requests5xx?: number;
  /** @format int64 */
  othersCount?: number;
  /** @format int64 */
  countryCount?: number;
}

export interface FirewallEvents {
  /** @format date-time */
  date?: Date;
  /** @format int32 */
  allows?: number | null;
  /** @format int32 */
  blocks?: number | null;
  /** @format int32 */
  anomalyScores?: number | null;
  /** @format int32 */
  logs?: number | null;
  /** @format int32 */
  challenges?: number | null;
  /** @format int32 */
  redirects?: number | null;
  /** @format int32 */
  total?: number | null;
}

export interface FirewallHitRuleDetails {
  ruleName?: string;
  action?: string;
  matchDetails?: FirewallHitRuleMatchDetails[];
  policyName?: any;
  ruleGroupDisplayName?: string;
  ruleId?: string;
  ruleDetailDisplay?: string;
  isCoreRuleset?: boolean;
  /** @format int64 */
  priority?: number;
}

export interface FirewallHitRuleMatchDetails {
  matchVariableName?: string;
  matchVariableValue?: string;
}

export interface FirewallKnownUserAgent {
  userAgent?: string;
  /** @format int32 */
  hits?: number;
}

export interface FirewallPolicyDto {
  id?: string;
  name?: string;
  /** @format int32 */
  eventsCount?: number;
  /** @format int32 */
  ignoredPotentialExclusionsCount?: number;
}

export interface FirewallPolicyExclusionDto {
  level?: string;
  /** @format int32 */
  order?: number;
  ruleId?: string;
  matchVariable?: string;
  selectorMatchOperator?: string;
  selector?: string;
  ruleGroup?: string | null;
}

export interface FirewallPolicyPotentialExclusionDetailDto {
  country?: string;
  matchVariableType?: string;
  matchVariableName?: string;
  matchVariableValue?: string;
  ruleId?: string;
  /** @format int32 */
  index?: number;
}

export interface FirewallPolicyPotentialExclusionDto {
  ruleId?: string;
  matchVariable?: string;
  matchValue?: string;
  /** @format int32 */
  hits?: number;
  isIgnored?: boolean;
  key?: string;
}

export interface FirewallPotentialExclusionLockDto {
  projectId?: string;
  policyName?: string;
  matchVariableName?: string;
  matchVariableValue?: string;
  ruleId?: string;
  firewallPolicyId?: string;
}

export interface FirewallRuleGroupDto {
  ruleId?: string;
  ruleGroupDisplayName?: string;
  ruleSetName?: string;
  ruleGroup?: string;
  ruleDetailDisplay?: string;
  state?: string;
  action?: string;
  /** @format int64 */
  exclusionsGlobalCount?: number;
  /** @format int64 */
  priority?: number;
  ruleGroupShort?: string;
  /** @format int64 */
  eventsTotalCount?: number;
  ruleType?: string;
  /** @format int64 */
  rateLimitDurationInMinutes?: number;
  /** @format int64 */
  rateLimitThreshold?: number;
  matchConditions?: string;
  id?: string;
  botRuleDetails?: FirewallBotRuleDetailsDto | null;
}

export interface FirewallRuleSampleDto {
  clientIp?: string;
  country?: string;
  /** @format int64 */
  ipHits?: number;
  hostName?: string;
  matchName?: string;
  matchValue?: string;
  /** @format int64 */
  matchHits?: number;
}

export interface FirewallTrackingBaseInfoDto {
  reference?: string | null;
  httpMethod?: string | null;
  userAgent?: string | null;
  ip?: string | null;
  /** @format int32 */
  httpStatusCode?: number | null;
  city?: string | null;
  country?: string | null;
  requestUrl?: string | null;
  rulesHit?: FirewallHitRuleDetails[];
  /** @format date-time */
  requestTime?: Date;
  verdict?: string | null;
}

export interface FirewallTrackingEventsSummary {
  /** @format date-time */
  date?: Date;
  /** @format int32 */
  allowEventsCount?: number | null;
  /** @format int32 */
  blockEventsCount?: number | null;
  /** @format int32 */
  logEventsCount?: number | null;
  /** @format int32 */
  challengeEventsCount?: number | null;
  /** @format int32 */
  redirectEventsCount?: number | null;
  /** @format int32 */
  anomalyScoreEventsCount?: number | null;
  /** @format int32 */
  implicitAllowEventsCount?: number | null;
}

export interface FirewallTrackingIpHistoryInfoDto {
  events?: FirewallTrackingEventsSummary[];
  knownUserAgents?: FirewallKnownUserAgent[];
  /** @format int64 */
  totalUserAgents?: number;
}

export interface FrontEndData {
  geoScopes?: DropdownOption[];
  metrics?: DropdownOption[];
  metricUnits?: DropdownOption[];
  mitigationEfforts?: DropdownOption[];
  recommendationStatuses?: DropdownOption[];
  recommendedActions?: DropdownOption[];
  mitigationActors?: DropdownOption[];
  metricUnitLookup?: Record<string, string>;
  metricOperators?: DropdownOption[];
  urlScopeOperators?: DropdownOption[];
  endpointTypes?: DropdownOption[];
  targetTypes?: DropdownOption[];
  platformTypes?: DropdownOption[];
  dashboardStyles?: DropdownOption[];
  timeRanges?: DropdownOption[];
  coreWebVitalMetrics?: DropdownOption[];
  devices?: DropdownOption[];
  auditTypes?: DropdownOption[];
  auditFrequencies?: DropdownOption[];
  endpointStages?: DropdownOption[];
  endpointClasses?: DropdownOption[];
}

export enum GeoScope {
  World = "World",
  Europe = "Europe",
  NorthAmerica = "NorthAmerica",
  LatinAmerica = "LatinAmerica",
  Africa = "Africa",
  Asia = "Asia",
  Oceania = "Oceania",
}

export interface GobelinDeviceDashboardAssignmentDto {
  id?: GobelinDeviceDashboardAssignmentId;
  gobelinDeviceId?: string;
  dashboardId?: string;
  note?: string | null;
}

export interface GobelinDeviceDashboardAssignmentId {
  /** @format uuid */
  value?: string;
}

export interface GobelinDeviceDto {
  id?: string;
  deviceIdentifier?: string;
  projectId?: string | null;
}

export interface GroupedFirewallRuleSamplesDto {
  ip?: string;
  country?: string;
  samples?: FirewallRuleSampleDto[];
  /** @format int64 */
  count?: number;
  hostNames?: string;
}

export interface IgnorePotentialExclusionRequest {
  projectId?: string;
  firewallPolicyId?: string;
  ruleId?: string;
  matchVariableValue?: string;
  matchVariableName?: string;
  isPermanent?: boolean;
}

export interface IgnoredPotentialExclusionDto {
  id?: string | null;
  projectId?: string | null;
  firewallPolicyId?: string;
  matchVariableName?: string | null;
  matchVariableValue?: string | null;
  ruleId?: string | null;
  userEmail?: string | null;
  /** @format date-time */
  createdAt?: Date;
}

export interface Int32Int32DatedValue {
  /** @format date-time */
  date?: Date;
  /** @format int32 */
  value1?: number;
  /** @format int32 */
  value2?: number;
}

export interface Int64DatedValue {
  /** @format date-time */
  date?: Date;
  /** @format int64 */
  value?: number;
}

export interface Lastchangedby {
  displayName?: string;
  url?: string;
  _links?: Links;
  id?: string;
  uniqueName?: string;
  imageUrl?: string;
  descriptor?: string;
}

export interface LatLong {
  latitude?: string;
  longitude?: string;
  /** @format double */
  radius?: number;
  /** @format int32 */
  ipHits?: number;
  /** @format int64 */
  countDropped?: number;
  /** @format int64 */
  countForwarded?: number;
}

export interface LogoDto {
  id?: LogoId;
  url?: string;
  base64?: string;
  isDefault?: boolean;
  /** @format int32 */
  usageCount?: number;
  /** @format date-time */
  created?: Date;
}

export interface LogoId {
  /** @format uuid */
  value?: string;
}

export interface Logs {
  /** @format int32 */
  id?: number;
  type?: string;
  url?: string;
}

export enum Metric {
  Undefined = "Undefined",
  TTFB = "TTFB",
  TTFBNearOrigin = "TTFBNearOrigin",
  TTFBFromChina = "TTFBFromChina",
  GlobalNetworkOverhead = "GlobalNetworkOverhead",
  GlobalDNSResolveTime = "GlobalDNSResolveTime",
  GooglebotTTFB = "GooglebotTTFB",
  CoreWebVitals = "CoreWebVitals",
  ThirtyDaysAvailabilityForInfrastructure = "ThirtyDaysAvailabilityForInfrastructure",
  ThirtyDaysAvailabilityForApplication = "ThirtyDaysAvailabilityForApplication",
  WAFBlock = "WAFBlock",
  WAFOwaspBlock = "WAFOwaspBlock",
}

export enum MetricOperator {
  Greater = "Greater",
  GreaterEqual = "GreaterEqual",
  LesserEqual = "LesserEqual",
  Lesser = "Lesser",
  Equal = "Equal",
}

export enum MetricUnit {
  Ms = "Ms",
  Percentage = "Percentage",
  Byte = "Byte",
  MegaBytes = "MegaBytes",
  Empty = "Empty",
}

export enum MitigationEffort {
  Minimal = "Minimal",
  FewHours = "FewHours",
  LessThanADay = "LessThanADay",
  TwoThreeDays = "TwoThreeDays",
  FewDays = "FewDays",
}

export interface NominateExclusionRequest {
  customerId?: string;
  projectId?: string;
  firewallPolicyId?: string;
  ruleName?: string;
  matchVariableName?: string;
  matchVariableValue?: string;
  ruleId?: string;
  timeSpan?: string;
}

export interface Orchestrationplan {
  planId?: string;
}

export interface PermissionData {
  customerName?: string;
  projectNames?: string[];
}

export interface PersistExpandedDeploymentsRequest {
  projectId?: string;
  expandedDeploymentIds?: string[];
}

export interface PhaseDetails {
  /** @format int32 */
  currentIndex?: number;
  /** @format int32 */
  totalPhases?: number;
  name?: string;
}

export interface PhasedAzureDevOpsBuildLogs {
  phase?: string;
  records?: TimelineRecordDto[];
  /** @format int32 */
  phaseId?: number;
  content?: string[];
  /** @format date-time */
  startTime?: Date;
  /** @format int32 */
  totalSeconds?: number | null;
  /** @format date-time */
  finishTime?: Date;
  status?: BuildPhaseStatus;
  phaseName?: string;
  isLocation?: boolean;
}

export interface Plan {
  planId?: string;
}

export interface Pool {
  /** @format int32 */
  id?: number;
  name?: string;
  isHosted?: boolean;
}

export interface Project {
  id?: string;
  name?: string;
  url?: string;
  state?: string;
  /** @format int32 */
  revision?: number;
  visibility?: string;
  /** @format date-time */
  lastUpdateTime?: Date;
}

export interface ProjectDto {
  id?: string;
  customerId?: string;
  name?: string;
  displayName?: string;
  subscriptionId?: string;
  shortName?: string;
  hasDeploymentsLinked?: boolean;
  hidePentesters?: string[];
}

export interface ProjectEnvironmentDto {
  id?: string;
  projectId?: string;
  name?: string;
}

export interface ProjectFirewallDisplaySettingsDto {
  projectId?: string;
  hideHighPriorityRulesForNonAdmin?: boolean;
}

export interface ProjectOverviewDto {
  /** @format int64 */
  domainsCount?: number | null;
  /** @format int64 */
  firewallBlocksCount?: number | null;
  /** @format int64 */
  totalEgressMb?: number | null;
  /** @format double */
  firewallPercentageBlocked?: number | null;
  hasRoutedEndpoints?: boolean;
}

export type Properties = object;

export interface Queue {
  /** @format int32 */
  id?: number;
  name?: string;
  pool?: Pool;
}

export interface RecommendationBusinessGoalImpactDto {
  linkId?: string;
  businessGoalId?: string;
  recommendationId?: string;
  /** @format date-time */
  date?: Date;
}

export interface RecommendationBusinessGoalLinkDto {
  id?: string;
  estimatedImpact?: string;
  businessGoalId?: string;
  recommendationId?: string;
  isNew?: boolean;
}

export interface RecommendationDto {
  id?: string;
  projectId?: string;
  category?: string;
  title?: string;
  mitigationImpact?: string;
  mitigationActors?: DropdownOption[];
  mitigationEffort?: MitigationEffort;
  isReadOnly?: boolean;
  /** @format int32 */
  mitigationEffortVal?: number;
  mitigationEffortDescription?: string;
  recommendationBody?: string;
  recommendedAction?: RecommendedAction;
  status?: RecommendationStatus;
  customerFacingId?: string;
  categoryIcon?: string;
  resolutionString?: string;
}

export enum RecommendationStatus {
  Draft = "Draft",
  Active = "Active",
  Solved = "Solved",
  Cancelled = "Cancelled",
}

export interface RecommendationTemplateDto {
  id?: string;
  category?: string;
  title?: string;
  mitigationImpact?: string;
  mitigationActors?: DropdownOption[];
  mitigationEffort?: MitigationEffort;
  /** @format int32 */
  mitigationEffortVal?: number;
  mitigationEffortDescription?: string;
  recommendationBody?: string;
  recommendedAction?: RecommendedAction;
  status?: RecommendationStatus;
  categoryIcon?: string;
}

export interface RecommendationVersionDto {
  category?: string;
  title?: string;
  mitigationImpact?: string;
  mitigationActors?: string;
  mitigationEffort?: MitigationEffort;
  /** @format int32 */
  mitigationEffortVal?: number;
  mitigationEffortDescription?: string;
  recommendedAction?: RecommendedAction;
  status?: RecommendationStatus;
  categoryIcon?: string;
  /** @format date-time */
  createdAt?: Date;
  id?: RecommendationVersionId;
  createdBy?: any;
  changelog?: string;
  recommendationBody?: string;
}

export interface RecommendationVersionId {
  /** @format uuid */
  value?: string;
}

export enum RecommendedAction {
  Fix = "Fix",
  Hold = "Hold",
  Escalate = "Escalate",
  Investigate = "Investigate",
}

export interface ReportAProblemRequest {
  projectId?: string;
  customerId?: string;
  body?: string;
  area?: string;
}

export interface ReportAWAFProblemRequest {
  customerId?: string;
  projectId?: string;
  body?: string;
  firewallPolicyId?: string;
}

export interface Repository {
  id?: string;
  type?: string;
  name?: string;
  url?: string;
  clean?: any;
  checkoutSubmodules?: boolean;
}

export interface RepositoryInfoDto {
  repositoryInfoId?: string;
  name?: string;
  displayName?: string;
  customerRepositoryUrl?: string;
  customerRepositoryBranch?: string;
  isCIEnabled?: boolean;
  customerId?: string;
  projectId?: string;
  system?: DeploymentSystem;
  /** @format int32 */
  buildDefinitionId?: number | null;
  /** @format int32 */
  releaseDefinitionId?: number | null;
  domains?: string[];
}

export interface Requestedby {
  displayName?: string;
  url?: string;
  _links?: Links;
  id?: string;
  uniqueName?: string;
  imageUrl?: string;
  descriptor?: string;
}

export interface Requestedfor {
  displayName?: string;
  url?: string;
  _links?: Links;
  id?: string;
  uniqueName?: string;
  imageUrl?: string;
  descriptor?: string;
}

export interface Result {
  isFailure?: boolean;
  isSuccess?: boolean;
  error?: string | null;
}

export interface Self {
  href?: string;
}

export interface SendBusinessGoalEmailRequest {
  title?: string;
  to?: string;
  customerId?: string;
}

export interface SendRecommendationEmailRequest {
  recommendationId?: string;
  to?: string;
}

export interface SetDashboardCustomIdRequest {
  id?: string;
  customId?: string;
}

export interface SetGobelinDeviceDashboardAssignmentRequest {
  projectId?: string;
  gobelinDeviceId?: string;
  dashboardId?: string;
  note?: string | null;
}

export interface SetProjectDefaultLogoRequest {
  projectId?: string;
  logoId?: LogoId;
}

export interface SetProjectFirewallDisplaySettingsRequest {
  projectId?: string;
  hideHighPriorityRulesForNonAdmin?: boolean;
}

export interface SetProjectFirewallSettingsRequest {
  projectId?: string;
  hidePentesters?: string[];
}

export interface SetSubscriptionLinkRequest {
  subscriptionId?: string;
  projectId?: string;
  customerId?: string;
}

export interface SetUserProjectFirewallPolicyContextRequest {
  projectId?: string;
  selectedFirewallPolicyId?: string;
}

export interface SetUserProjectFirewallPolicyFiltersRequest {
  firewallPolicyId?: string;
  timeSpan?: string;
  hasEventsEnabled?: boolean;
  hasExclusionsEnabled?: boolean;
  search?: string;
  state?: string[];
  actions?: string[];
  ruleGroups?: string[];
}

export interface Sourceversiondisplayuri {
  href?: string;
}

export interface StringStringKeyValuePair {
  key?: string;
  value?: string;
}

export interface SubscriptionDto {
  subscriptionId?: string;
  subscriptionName?: string;
  offerId?: string;
  planId?: string;
  projectLink?: SubscriptionProjectLink;
  isActive?: boolean;
}

export interface SubscriptionProjectLink {
  id?: string | null;
  subscriptionId?: string;
  projectId?: string | null;
  customerId?: string | null;
}

export enum TimeRange {
  FifteenMinutes = "FifteenMinutes",
  ThirtyMinutes = "ThirtyMinutes",
  OneHour = "OneHour",
  FourHours = "FourHours",
  EightHours = "EightHours",
  Day = "Day",
  Week = "Week",
  FifteenDays = "FifteenDays",
  ThirtyDays = "ThirtyDays",
}

export interface Timeline {
  href?: string;
}

export interface TimelineRecordDto {
  /** @format date-time */
  startTime?: Date;
  /** @format date-time */
  finishTime?: Date;
  state?: string;
  logContent?: string[];
  result?: string;
  name?: string;
}

export interface Triggeredbybuild {
  /** @format int32 */
  id?: number;
  buildNumber?: string;
  definition?: Definition;
  project?: Project;
  priority?: string;
  reason?: string;
  triggeredByBuild?: any;
  appendCommitMessageToRunName?: boolean;
}

export interface Triggerinfo {
  cisourceBranch?: string;
  cisourceSha?: string;
  cimessage?: string;
  citriggerRepository?: string;
}

export interface UnignorePotentialExclusionRequest {
  projectId?: string;
  firewallPolicyId?: string;
  ignoredPotentialExclusionId?: string;
  ruleId?: string;
  matchVariableValue?: string;
  matchVariableName?: string;
}

export interface UpdateAccessManagementRuleRequest {
  id?: string;
  email?: string;
  customerIds?: string[];
  projectIds?: string[];
  isAdmin?: boolean;
}

export interface UpdateAnonymizationRuleRequest {
  projectId?: string;
  id?: string;
  key?: string;
  value?: string;
}

export interface UpdateAuditTemplateRequest {
  id?: string;
  runFrequency?: AuditFrequency;
}

export interface UpdateBusinessGoalRequest {
  id?: string;
  value?: string;
}

export interface UpdateContextRequest {
  contextCustomerId?: string | null;
  contextProjectId?: string | null;
}

export interface UpdateCustomerRequest {
  customerId?: string;
  displayName?: string;
}

export interface UpdateDashboardFirewallRequest {
  dashboardId?: string;
  whitelistId?: string | null;
}

export interface UpdateDashboardRequest {
  id?: string;
  title?: string;
  style?: DashboardStyle;
  logoId?: LogoId | null;
}

export interface UpdateDashboardViewRequest {
  id?: string;
  dashboardId?: string;
  title?: string | null;
  /** @format int32 */
  duration?: number;
  zoomBox?: ZoomBoxConfigurationDto | null;
  timeRange?: TimeRange | null;
  hostInclude?: string[] | null;
  /** @format int32 */
  sloMs?: number | null;
  isEnabled?: boolean;
  customJson?: string | null;
  dashboardViewFilters?: DashboardViewFilter[];
}

export interface UpdateGobelinDeviceRequest {
  id?: string;
  deviceIdentifier?: string;
  projectId?: string | null;
}

export interface UpdateProjectRequest {
  projectId?: string;
  displayName?: string;
}

export interface UpdateRecommendationRequest {
  recommendationId?: string;
  category?: string;
  title?: string;
  mitigationImpact?: string;
  mitigationActors?: string[];
  mitigationEffort?: MitigationEffort;
  recommendationBody?: string;
  recommendedAction?: RecommendedAction;
  status?: RecommendationStatus;
  createdBy?: string;
  links?: RecommendationBusinessGoalLinkDto[];
}

export interface UpdateRecommendationTemplateRequest {
  id?: string;
  category?: string;
  title?: string;
  mitigationImpact?: string;
  mitigationActors?: string[];
  mitigationEffort?: MitigationEffort;
  recommendationBody?: string;
  recommendedAction?: RecommendedAction;
  status?: RecommendationStatus;
}

export interface UpdateRepositoryInfoRequest {
  repositoryInfoId?: string;
  customerId?: string;
  projectId?: string;
  displayName?: string;
  isCIEnabled?: boolean;
  customerRepositoryUrl?: string;
  customerRepositoryBranch?: string;
  /** @format int32 */
  buildDefinitionId?: number | null;
  /** @format int32 */
  releaseDefinitionId?: number | null;
  domains?: string[];
}

export interface UpdateUserProjectDisplaySettingsRequest {
  id?: string;
  isDemoModeEnabled?: boolean;
}

export interface UpdateWhitelistFirewallRuleRequest {
  ipRule?: string;
  whitelistId?: string;
  whitelistFirewallRuleId?: string;
  description?: string;
}

export interface UpdateWhitelistRequest {
  whitelistId?: string;
  name?: string;
}

export enum UrlScopeOperator {
  PathStartsWith = "PathStartsWith",
  PathContains = "PathContains",
  PathIs = "PathIs",
}

export interface UserContextDto {
  contextCustomerId?: string | null;
  contextProjectId?: string | null;
  expandedDeployments?: ExpandedDeployments;
}

export interface UserProfileDto {
  email?: string;
  id?: string;
  isAdmin?: boolean;
  hasAccess?: boolean;
  context?: UserContextDto;
}

export interface UserProjectDisplaySettingsDto {
  id?: string;
  isDemoModeEnabled?: boolean;
  anonymizationRules?: AnonymizationRuleDto[];
}

export interface UserProjectFirewallPolicyContextDto {
  selectedFirewallPolicyId?: string | null;
}

export interface UserProjectFirewallPolicyFiltersDto {
  userId?: string;
  firewallPolicyId?: string;
  timeSpan?: string;
  ruleGroups?: string[];
  hasEventsEnabled?: boolean;
  hasExclusionsEnabled?: boolean;
  actions?: string[];
  search?: string;
  state?: string[];
}

export interface Validationresult {
  result?: string;
  message?: string;
}

export interface Web {
  href?: string;
}

export interface WhitelistDto {
  projectId?: string;
  whitelistId?: string;
  name?: string;
  /** @format date-time */
  createdAt?: Date;
}

export interface WhitelistFirewallRuleDto {
  whitelistFirewallRuleId?: string;
  whitelistId?: string;
  /** @format date-time */
  createdAt?: Date;
  /** @format date-time */
  updatedAt?: Date;
  description?: string;
  ipRule?: string;
  projectId?: string;
}

export interface ZoomBoxConfigurationDto {
  /** @format double */
  topLeftLat?: number | null;
  /** @format double */
  topLeftLng?: number | null;
  /** @format double */
  bottomRightLat?: number | null;
  /** @format double */
  bottomRightLng?: number | null;
  zoomBoxThumbnailBase64?: string | null;
  zoomBoxThumbnailUrl?: string | null;
  isDefault?: boolean;
}

export interface Links {
  self?: Self;
  web?: Web;
  sourceVersionDisplayUri?: Sourceversiondisplayuri;
  timeline?: Timeline;
  badge?: Badge;
}
